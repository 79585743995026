export const PUBLISHED_STATE = 'wstate:Published';
export const IN_PRODUCTION_STATE = 'wstate:InProduction';
export const CREATED_STATE = 'wstate:Created';
export const COMPLETED_STATE = 'wstate:Complete';

export const STANDARD_PUBLICATION_TYPE = 'wpub:IssueVirtual';
export const SPECIAL_PUBLICATION_TYPE = 'wpub:IssueSpecial';

export const LIVE_ISSUES_FILTER = { productState: PUBLISHED_STATE };
export const NON_LIVE_ISSUES_FILTER = { productState: CREATED_STATE };

export const UI_STATES_INFO = {
  SavedForLater: {
    title: 'Saved For Later',
    color: '#613BEA',
  },
  Published: {
    title: 'Published',
    color: '#46DB00',
  },
  SentToWOL: {
    title: 'Sent To WOL',
    color: '#FF9900',
  },
  Partial: {
    title: 'Partial',
    color: '#D3C9F8',
  },
  DeliveryError: {
    title: 'Literatum Processing Failed',
    color: '#FF0000',
  },
};
